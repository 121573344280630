import { createReducer, createActions } from "reduxsauce";

/* --------------------- Types and Action Creators ---------------- */
const { Types, Creators } = createActions({
  setChannel: ["channel"],
  setChannelList: ["channelList"],
  addChannel: ["channel"],
  setMessages: ["messages"],
  setUnreadCountMap: ["unreadCountMap"],
  updateUnreadCount: ["channelUrl", "unreadCount"],
  addMessage: ["message"],
  upsertUser: ["user"],
  setUsers: ["users"],
});

export const ChatTypes = Types;

export default Creators;

/* --------------------- Selectors ---------------- */
export const ChatSelectors = {
  selectChannel: (state) => state.chat.channel,
  selectChannelList: (state) => state.chat.channelList,
  selectMembers: (state) =>
    state.chat.channel ? state.chat.channel.members : [],
  selectGroupChannels: (state) =>
    state.chat.channelList.filter((c) => c.customType === "group"),
  selectMessages: (state) => state.chat.messages,
  selectUnreadCountMap: (state) => state.chat.unreadCountMap,
  selectUsers: (state) => state.chat.users,
};

/* --------------------- Initial State ----------------- */
export const INITIAL_STATE = {
  channel: null,
  channelList: [],
  messages: [],
  unreadCountMap: {},
  users: [],
};

/* ------------------- Reducers --------------------- */
export const setChannel = (state, { channel }) => ({
  ...state,
  channel,
});

export const setChannelList = (state, { channelList }) => ({
  ...state,
  channelList,
});

export const setMessages = (state, { messages }) => ({
  ...state,
  messages,
});

export const setUsers = (state, { users }) => ({
  ...state,
  users,
});

export const addMessage = (state, { message }) => ({
  ...state,
  messages: [...state.messages, message],
});

export const addChannel = (state, { channel }) => ({
  ...state,
  channelList: [...state.channelList, channel],
});

export const setUnreadCountMap = (state, { unreadCountMap }) => ({
  ...state,
  unreadCountMap,
});

export const updateUnreadCount = (state, { channelUrl, unreadCount }) => ({
  ...state,
  unreadCountMap: {
    ...state.unreadCountMap,
    [channelUrl]: unreadCount,
  },
});

export const upsertUser = (state, { user }) => {
  const index = state.users.findIndex((u) => u.userId === user.userId);
  let newUsers;
  if (index === -1) {
    newUsers = [user, ...state.users];
  } else {
    newUsers = state.users.map((u) => (u.userId === user.userId ? user : u));
  }

  return {
    ...state,
    users: newUsers,
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_CHANNEL]: setChannel,
  [Types.SET_CHANNEL_LIST]: setChannelList,
  [Types.SET_MESSAGES]: setMessages,
  [Types.ADD_MESSAGE]: addMessage,
  [Types.ADD_CHANNEL]: addChannel,
  [Types.SET_USERS]: setUsers,
  [Types.UPSERT_USER]: upsertUser,
  [Types.UPDATE_UNREAD_COUNT]: updateUnreadCount,
  [Types.SET_UNREAD_COUNT_MAP]: setUnreadCountMap,
});
