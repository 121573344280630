import React from "react";
import moment from "moment";
import { IonLabel, IonItem, IonAvatar } from "@ionic/react";
import MsgRenderer from "./MsgRenderer";

function Message({ data }) {
  const Renderer = MsgRenderer[data.customType] || MsgRenderer.text;
  return (
    <IonItem lines="none">
      <IonAvatar slot="start">
        <img src={data._sender.profileUrl} alt={data._sender.nickname} />
      </IonAvatar>
      <IonLabel>
        <h3>
          <b>{data._sender.nickname}</b>&nbsp;
          <small>{moment(data.createdAt).format("hh:mm A")}</small>
        </h3>
        <Renderer data={data} />
      </IonLabel>
    </IonItem>
  );
}

export default Message;
