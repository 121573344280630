import React from "react";
import { Link } from "react-router-dom";
import { PageLayout } from "../containers/Layout";
import CenterContainer from "../components/CenterContainer";
import SignupForm from "../containers/SignupForm";

function SignupPage() {
  return (
    <PageLayout>
      <CenterContainer>
        <h1 className="title">Sign Up</h1>
        <p className="subtitle">
          Let's get started by creating your account.
          <br />
          Add your information below to begin.
        </p>
        <SignupForm />
        <p className="helper-text">
          Have an account?{" "}
          <Link className="link" to="/auth/login">
            Log In
          </Link>
        </p>
      </CenterContainer>
    </PageLayout>
  );
}

export default SignupPage;
