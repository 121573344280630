import React, { Component } from "react";
import { Formik, Field } from "formik";
import {
  IonButton,
  IonLoading,
  IonContent,
  IonFooter,
  IonButtons,
  IonToolbar,
} from "@ionic/react";
import InputField from "../../components/InputField";
import inviteService from "../../services/invite";
import { toast } from "../../toast";
import Modal from "../../components/Modal";
import validationSchema from "./schema";

class AddMemberModal extends Component {
  handleSubmit = async (values, actions) => {
    const { onClose } = this.props;
    actions.setSubmitting(true);
    try {
      await inviteService.inviteEmail(values.email);
      actions.setSubmitting(false);
      toast("Invitation sent");
      onClose();
    } catch (e) {
      console.error(e);
      toast(e.message);
      actions.setSubmitting(false);
    }
  };

  renderForm = ({ isSubmitting, handleSubmit }) => {
    return (
      <>
        <IonContent>
          <IonLoading
            message="Please wait..."
            duration={0}
            isOpen={isSubmitting}
          />
          <Field
            component={InputField}
            name="email"
            type="email"
            label="Email Address"
            required
          />
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton
                fill="solid"
                color="primary"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Invite
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </>
    );
  };

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <Modal isOpen={isOpen} onClose={onClose} title="Add Member">
        <Formik
          initialValues={{ name: "" }}
          onSubmit={this.handleSubmit}
          validationSchema={validationSchema}
          children={this.renderForm}
        />
      </Modal>
    );
  }
}

export default AddMemberModal;
