import React from "react";
import { IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { Route, Redirect } from "react-router-dom";
import Menu from "./containers/Menu";
import Chat from "./pages/Chat";

const MainRoutes = () => {
  return (
    <IonRouterOutlet>
      <IonSplitPane contentId="main">
        <Menu />
        <IonRouterOutlet id="main">
          <Route path="/" component={Chat} exact={true} />
          <Route component={() => <Redirect to="/" />} />
        </IonRouterOutlet>
      </IonSplitPane>
    </IonRouterOutlet>
  );
};

export default MainRoutes;
