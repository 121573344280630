import React, { Component } from "react";
import { Formik, Field } from "formik";
import { connect } from "react-redux";
import {
  IonAvatar,
  IonButton,
  IonLoading,
  IonList,
  IonItem,
  IonContent,
  IonFooter,
  IonButtons,
  IonToolbar,
} from "@ionic/react";
import AuthActions, { AuthSelectors } from "../../redux/AuthRedux";
import InputField from "../../components/InputField";
import Upload from "../../components/form/Upload";
import userService from "../../services/user";
import sendbird from "../../services/sendbird";
import { toast } from "../../toast";
import validationSchema from "./schema";
import Modal from "../../components/Modal";

const INITIAL_VALUES = {
  name: "",
  email: "",
  oldPassword: "",
  newPassword: "",
};

class AccountSettingsForm extends Component {
  state = {
    uploading: false,
  };

  handleUpload = async (files) => {
    const { currentUser, updateUser } = this.props;
    const blob = files[0];
    this.setState({ uploading: true });

    if (!blob.name) {
      blob.name = currentUser.uid + ".jpg";
    }

    sendbird.updateCurrentUserInfoWithProfileImage(
      currentUser.displayName,
      blob,
      (resp, error) => {
        this.setState({ uploading: false });

        if (error) {
          console.log(error);
          toast(error.message || "Failed to upload profile image");
          return;
        }

        updateUser({
          ...currentUser,
          profileUrl: resp.profileUrl,
        });
      }
    );
  };

  handleSubmit = async (values, actions) => {
    const { updateUser, currentUser, onClose } = this.props;

    actions.setSubmitting(true);
    try {
      await userService.save(values);
      updateUser({
        ...currentUser,
        displayName: values.name,
        email: values.email,
      });
      sendbird.updateCurrentUserInfo(values.name, "");
      actions.setSubmitting(false);
      toast("Updated profile successfully");
      onClose();
    } catch (e) {
      console.error(e);
      toast(e.message);
      actions.setSubmitting(false);
    }
  };

  renderForm = ({ isSubmitting, handleSubmit }) => {
    const { uploading } = this.state;
    const { currentUser } = this.props;
    return (
      <>
        <IonContent>
          <IonLoading
            message="Please wait..."
            duration={0}
            isOpen={isSubmitting}
          />
          <IonList>
            <IonItem>
              <IonAvatar slot="start">
                <img
                  src={currentUser.profileUrl}
                  alt={currentUser.displayName}
                />
              </IonAvatar>
              <Upload
                files={[]}
                onChange={this.handleUpload}
                placeholder="Upload"
                disabled={uploading}
              />
            </IonItem>
            <Field
              component={InputField}
              name="name"
              label="Full Name"
              required
            />
            <Field
              component={InputField}
              name="email"
              type="email"
              label="Email Address"
              required
            />
            <Field
              component={InputField}
              name="oldPassword"
              type="password"
              label="Current Password"
              required
            />
            <Field
              component={InputField}
              name="newPassword"
              type="password"
              label="New Password"
            />
          </IonList>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton
                fill="solid"
                color="primary"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Save
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </>
    );
  };

  render() {
    const { currentUser, onClose, isOpen } = this.props;

    return (
      <Modal isOpen={isOpen} onClose={onClose} title="Settings">
        <Formik
          initialValues={{
            ...INITIAL_VALUES,
            name: currentUser.displayName,
            email: currentUser.email,
          }}
          onSubmit={this.handleSubmit}
          validationSchema={validationSchema}
          children={this.renderForm}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: AuthSelectors.selectUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch(AuthActions.setUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettingsForm);
