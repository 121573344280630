import React, { Component } from "react";
import { Formik, Field, Form } from "formik";
import { IonButton, IonLoading } from "@ionic/react";
import InputField from "../../components/InputField";
import { loginUser } from "../../services/firebase";
import validationSchema from "./schema";

class LoginForm extends Component {
  handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    await loginUser(values.email, values.password);
    actions.setSubmitting(false);
  };

  renderForm = ({ isSubmitting, values }) => {
    return (
      <Form>
        <IonLoading
          message="Please wait..."
          duration={0}
          isOpen={isSubmitting}
        />
        <Field
          component={InputField}
          name="email"
          type="email"
          label="Email"
          required
        />
        <Field
          component={InputField}
          name="password"
          label="Password"
          type="password"
          required
        />
        <IonButton
          color="primary"
          expand="block"
          disabled={isSubmitting}
          type="submit"
        >
          Log In
        </IonButton>
      </Form>
    );
  };

  render() {
    return (
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={this.handleSubmit}
        validationSchema={validationSchema}
        children={this.renderForm}
      />
    );
  }
}

export default LoginForm;
