import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonIcon,
} from "@ionic/react";
import MessageForm from "../containers/MessageForm";
import InviteModal from "../containers/InviteModal";
import MessageList from "../containers/MessageList";
import ChatActions, { ChatSelectors } from "../redux/ChatRedux";
import { AuthSelectors } from "../redux/AuthRedux";
import sendbird, { getCurrentChannel } from "../services/sendbird";
import transformChannel from "../transform/channel";
import transformUser from "../transform/user";
import { getChannelName } from "../utils/channel";
import { personAddOutline } from "ionicons/icons";

function Chat() {
  const channel = useSelector(ChatSelectors.selectChannel);
  const messages = useSelector(ChatSelectors.selectMessages);
  const currentUser = useSelector(AuthSelectors.selectUser);
  const dispatch = useDispatch();
  const [invite, setInvite] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    let timeout;

    if (ref.current) {
      setTimeout(() => {
        ref.current.scrollToBottom(100);
      }, 50);
    }

    return () => clearTimeout(timeout);
  }, [messages.length]);

  useEffect(() => {
    let timeout;
    if (channel) {
      timeout = setTimeout(() => {
        const sbChannel = getCurrentChannel();
        sbChannel.markAsRead();
        dispatch(ChatActions.updateUnreadCount(sbChannel.url, 0));
      }, 500);
    }

    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  useEffect(() => {
    const ChannelHandler = new sendbird.ChannelHandler();
    const uuid = v4();

    ChannelHandler.onChannelChanged = (msgChannel) => {
      dispatch(
        ChatActions.updateUnreadCount(
          msgChannel.url,
          msgChannel.unreadMessageCount
        )
      );
    };

    ChannelHandler.onUserReceivedInvitation = (
      groupChannel,
      inviter,
      invitees
    ) => {
      const me = invitees.find((i) => i.userId === currentUser.uid);

      if (me) {
        dispatch(ChatActions.addChannel(transformChannel(groupChannel)));

        if (groupChannel.customType === "1-1") {
          dispatch(ChatActions.upsertUser(transformUser(inviter)));
        }
      }
    };

    ChannelHandler.onUserJoined = (joinedChannel, joinedUser) => {
      if (joinedChannel.url === "ANNOUNCEMENTS") {
        dispatch(ChatActions.upsertUser(transformUser(joinedUser)));
      }
    };

    sendbird.addChannelHandler(uuid, ChannelHandler);

    return () => {
      sendbird.removeChannelHandler(uuid);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{getChannelName(channel, currentUser)}</IonTitle>
          {channel && channel.customType === "group" && (
            <IonButtons slot="end">
              <IonButton onClick={() => setInvite(true)}>
                <IonIcon slot="icon-only" icon={personAddOutline} />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>

      <IonContent ref={ref}>
        {channel ? (
          <MessageList channel={channel} />
        ) : (
          <IonTitle>
            Hello {currentUser.displayName}! Select user or channel to chat
          </IonTitle>
        )}
      </IonContent>
      {channel && (
        <InviteModal isOpen={invite} onClose={() => setInvite(false)} />
      )}
      {channel && <MessageForm channel={channel} />}
    </IonPage>
  );
}

export default Chat;
