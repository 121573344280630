import React from "react";
import "./CenterContainer.css";

const CenterContainer = (props) => {
  return (
    <div className="center-container">
      <div className="center-container-inner" {...props} />
    </div>
  );
};

export default CenterContainer;
