import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import {
  IonButton,
  IonLoading,
  IonContent,
  IonFooter,
  IonButtons,
  IonToolbar,
} from "@ionic/react";
import InputField from "../../components/InputField";
import { createGroupChannel, setCurrentChannel } from "../../services/sendbird";
import { toast } from "../../toast";
import ChatActions from "../../redux/ChatRedux";
import { AuthSelectors } from "../../redux/AuthRedux";
import transformChannel from "../../transform/channel";
import validationSchema from "./schema";
import Modal from "../../components/Modal";

class AddChannelModal extends Component {
  handleSubmit = async (values, actions) => {
    const { currentUser, setChannel, addChannel, onClose } = this.props;
    actions.setSubmitting(true);
    try {
      const newChannel = await createGroupChannel(
        [currentUser.userId],
        false,
        values.name,
        "group"
      );
      setCurrentChannel(newChannel);
      setChannel(transformChannel(newChannel));
      addChannel(transformChannel(newChannel));
      actions.setSubmitting(false);
      onClose();
    } catch (e) {
      toast(e.message);
      actions.setSubmitting(false);
    }
  };

  renderForm = ({ isSubmitting, handleSubmit }) => {
    return (
      <>
        <IonContent>
          <IonLoading
            message="Please wait..."
            duration={0}
            isOpen={isSubmitting}
          />
          <Field
            component={InputField}
            name="name"
            label="Channel Name"
            required
          />
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton
                fill="solid"
                color="primary"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Create
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </>
    );
  };

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <Modal isOpen={isOpen} onClose={onClose} title="Add Channel">
        <Formik
          initialValues={{ name: "" }}
          onSubmit={this.handleSubmit}
          validationSchema={validationSchema}
          children={this.renderForm}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: AuthSelectors.selectUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  setChannel: (channel) => dispatch(ChatActions.setChannel(channel)),
  addChannel: (channel) => dispatch(ChatActions.addChannel(channel)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddChannelModal);
