import { createReducer, createActions } from "reduxsauce";

/* --------------------- Types and Action Creators ---------------- */
const { Types, Creators } = createActions({
  startup: [],
  setLoaded: ["loaded"]
});

export const AppTypes = Types;

export default Creators;

/* --------------------- Selectors ---------------- */
export const AppSelectors = {
  selectLoaded: state => state.app.loaded
};

/* --------------------- Initial State ----------------- */
export const INITIAL_STATE = {
  loaded: false
};

/* ------------------- Reducers --------------------- */
export const setLoaded = (state, { loaded }) => ({
  ...state,
  loaded
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_LOADED]: setLoaded
});
