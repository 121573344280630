import React from "react";
import { Link } from "react-router-dom";
import { PageLayout } from "../containers/Layout";
import CenterContainer from "../components/CenterContainer";
import LoginForm from "../containers/LoginForm";

function LoginPage() {
  return (
    <PageLayout>
      <CenterContainer>
        <h1 className="title">Log In</h1>
        <p className="subtitle">
          Let's get started by creating your account.
          <br />
          Add your information below to begin.
        </p>
        <LoginForm />
        <p className="helper-text">
          Forgot your password?{" "}
          <Link className="link" to="/auth/reset-password">
            Reset your password
          </Link>
        </p>
        <p className="helper-text">
          Don't have an account?{" "}
          <Link className="link" to="/auth/signup">
            Sign up
          </Link>
        </p>
      </CenterContainer>
    </PageLayout>
  );
}

export default LoginPage;
