import Sendbird from "sendbird";

const sb = new Sendbird({ appId: "0B3104BC-8E9C-4A57-BB49-8C4B0B12B2DD" });

let currentChannel;

export function setCurrentChannel(newChannel) {
  currentChannel = newChannel;
}

export function getCurrentChannel() {
  return currentChannel;
}

export function connect(user) {
  return new Promise((resolve, reject) => {
    sb.connect(user.uid, (sbUser, error) => {
      console.log("connect", sbUser, error);
      if (error) {
        return reject(error);
      }

      sb.updateCurrentUserInfo(user.displayName, "", (resp, error1) => {
        console.log("updateCurrentUserInfo", resp, error);
        if (error1) {
          return reject(error1);
        }

        return resolve(resp);
      });
    });
  });
}

export function disconnect() {
  return new Promise((resolve, reject) => {
    sb.disconnect((resp, error) => {
      console.log("disconnect", resp, error);
      if (error) {
        return reject(error);
      }

      return resolve(resp);
    });
  });
}

export function createGroupChannel(
  userIds,
  isDistinct = true,
  name,
  chatType = "1-1"
) {
  return new Promise((resolve, reject) => {
    sb.GroupChannel.createChannelWithUserIds(
      userIds,
      isDistinct,
      name || userIds.join(","),
      "",
      "",
      chatType,
      (channel, error) => {
        console.log("create group channel", channel, error);

        if (error) {
          return reject(error);
        }

        return resolve(channel);
      }
    );
  });
}

export function listAllUsers() {
  return new Promise((resolve, reject) => {
    const query = sb.createUserListQuery();
    query.limit = 100;
    query.next((users, error) => {
      if (error) {
        return reject(error);
      }

      return resolve(users);
    });
  });
}

export function getGroupChannel(url) {
  return new Promise((resolve, reject) => {
    sb.GroupChannel.getChannel(url, (resp, error) => {
      console.log("getGroupChannel", resp, error);
      if (error) {
        return reject(error);
      }

      return resolve(resp);
    });
  });
}

export function getDefaultChannel(currentUser) {
  return getGroupChannel("ANNOUNCEMENTS").catch(() => {
    const params = new sb.GroupChannelParams();
    params.isPublic = true;
    params.isEphemeral = false;
    params.isDistinct = false;
    params.addUserIds([currentUser.uid]);
    params.operatorIds = [currentUser.uid]; // Or .operators(Array<User>)
    params.name = "Announcements";
    params.channelUrl = "ANNOUNCEMENTS"; // In a group channel, you can create a new channel by specifying its unique channel URL in a 'GroupChannelParams' object.
    params.customType = "group";

    return new Promise((resolve, reject) => {
      sb.GroupChannel.createChannel(params, function (groupChannel, error) {
        if (error) {
          reject(error);
          return;
        }

        resolve(groupChannel);
      });
    });
  });
}

export function joinChannel(channel) {
  return new Promise((resolve, reject) => {
    channel.join((resp, error) => {
      if (error) reject(error);
      else resolve(resp);
    });
  });
}

export function listGroupChannels(limit = 100) {
  return new Promise((resolve, reject) => {
    const channelListQuery = sb.GroupChannel.createMyGroupChannelListQuery();
    channelListQuery.includeEmpty = true;
    channelListQuery.order = "chronological"; // 'chronological', 'latest_last_message', 'channel_name_alphabetical', and 'metadata_value_alphabetical'
    channelListQuery.limit = limit; // The value of pagination limit could be set up to 100.

    channelListQuery.next((channelList, error) => {
      if (error) {
        return reject(error);
      }

      console.log("all channels", channelList);

      return resolve(channelList);
    });
  });
}

export default sb;
