export default function transformMessage(msg) {
  return {
    messageId: msg.messageId,
    message: msg.message,
    customType: msg.customType,
    file: msg.file,
    url: msg.url,
    createdAt: msg.createdAt,
    _sender: {
      userId: msg._sender.userId,
      nickname: msg._sender.nickname,
      profileUrl: msg._sender.profileUrl
    }
  };
}
