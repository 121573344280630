import React from "react";
import { IonButton } from "@ionic/react";
import { PageLayout } from "../containers/Layout";
import CenterContainer from "../components/CenterContainer";

function Home({ history }) {
  return (
    <PageLayout>
      <CenterContainer>
        <h1 className="title">Space</h1>
        <p className="subtitle">
          Get started by signing up for a new account
          <br />
          or logging in to existing account
        </p>
        <div className="mt-50">
          <IonButton
            color="primary"
            expand="block"
            onClick={() => history.push("/auth/signup")}
          >
            Sign Up
          </IonButton>
          <IonButton
            color="primary"
            expand="block"
            fill="outline"
            onClick={() => history.push("/auth/login")}
          >
            Log In
          </IonButton>
        </div>
      </CenterContainer>
    </PageLayout>
  );
}

export default Home;
