import { createReducer, createActions } from "reduxsauce";

/* --------------------- Types and Action Creators ---------------- */
const { Types, Creators } = createActions({
  changeAuth: ["user"],
  setUser: ["user"],
  logout: null
});

export const AuthTypes = Types;

export default Creators;

/* --------------------- Selectors ---------------- */
export const AuthSelectors = {
  selectUser: state => state.auth.user,
  selectLoaded: state => state.auth.loaded
};

/* --------------------- Initial State ----------------- */
export const INITIAL_STATE = {
  user: null,
  loaded: false
};

/* ------------------- Reducers --------------------- */
export const setUser = (state, { user }) => ({
  ...state,
  loaded: true,
  user
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_USER]: setUser
});
