import firebase from "./firebase";

function reauthenticate(user, email, password) {
  const credential = firebase.auth.EmailAuthProvider.credential(
    email,
    password
  );

  return user.reauthenticateWithCredential(credential);
}

async function save(data) {
  const user = firebase.auth().currentUser;

  await reauthenticate(user, data.email, data.oldPassword);
  await user.updateProfile({
    displayName: data.name,
  });
  await user.updateEmail(data.email);

  if (data.newPassword) {
    await user.updatePassword(data.newPassword);
    await firebase.login(user.email, data.newPassword);
  }

  return data;
}

export default {
  save,
};
