import React from "react";
import {
  IonIcon,
  IonHeader,
  IonTitle,
  IonButtons,
  IonButton,
  IonToolbar,
  IonModal,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import "./Modal.css";

function Modal({ title, children, onClose, ...props }) {
  return (
    <IonModal onDidDismiss={onClose} {...props}>
      <IonHeader>
        <IonToolbar>
          <IonTitle slot="start">{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {children}
    </IonModal>
  );
}

export default Modal;
