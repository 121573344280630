import React, { useState } from "react";
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonBadge,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";
import {
  logOutOutline,
  personOutline,
  chatbubbleEllipsesOutline,
  addCircleOutline,
  settingsOutline,
} from "ionicons/icons";

import { toast } from "../../toast";
import { auth } from "../../services/firebase";
import {
  createGroupChannel,
  setCurrentChannel,
  getGroupChannel,
} from "../../services/sendbird";
import { AuthSelectors } from "../../redux/AuthRedux";
import ChatActions, { ChatSelectors } from "../../redux/ChatRedux";
import "./Menu.css";
import transformChannel from "../../transform/channel";
import AddChannelModal from "../AddChannelModal";
import AccountSettingsModal from "../AccountSettingsModal";
import AddMemberModal from "../AddMemberModal";

const Menu = () => {
  const history = useHistory();
  const location = useLocation();
  const [memberModal, setMemberModal] = useState(false);
  const [channelModal, setChannelModal] = useState(false);
  const [settingModal, setSettingModal] = useState(false);
  const currentUser = useSelector(AuthSelectors.selectUser);
  const channel = useSelector(ChatSelectors.selectChannel);
  const groups = useSelector(ChatSelectors.selectGroupChannels);
  const allGroups = useSelector(ChatSelectors.selectChannelList);
  const unreadMessageMap = useSelector(ChatSelectors.selectUnreadCountMap);
  const users = useSelector(ChatSelectors.selectUsers);
  const dispatch = useDispatch();

  const handleLogout = () => {
    auth.signOut();
  };

  const handleSelectGroup = async (group) => {
    if (channel && channel.id === group.url) return history.push("/");
    const newChannel = await getGroupChannel(group.url);
    setCurrentChannel(newChannel);
    dispatch(ChatActions.setChannel(transformChannel(newChannel)));
    history.push("/");
  };

  const handleSelectUser = async (user) => {
    if (channel && channel.otherUserId === user.userId)
      return history.push("/");

    try {
      const newChannel = await createGroupChannel([
        currentUser.uid,
        user.userId,
      ]);

      setCurrentChannel(newChannel);
      dispatch(
        ChatActions.setChannel({
          ...transformChannel(newChannel),
          otherUserId: user.userId,
        })
      );
      history.push("/");
    } catch (e) {
      toast(e.message);
    }
  };

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList className="sidelist sidelist--top">
          <IonListHeader>Space</IonListHeader>
          <IonNote>{currentUser.displayName}</IonNote>
        </IonList>
        <IonList className="sidelist">
          <IonListHeader>Groups</IonListHeader>
          {groups.map((group, index) => {
            return (
              <IonMenuToggle key={group.id} autoHide={false}>
                <IonItem
                  className={clsx({
                    selected:
                      channel &&
                      channel.id === group.id &&
                      location.pathname === "/",
                  })}
                  onClick={() => handleSelectGroup(group)}
                  button
                  lines="none"
                  detail={false}
                >
                  <IonIcon slot="start" icon={chatbubbleEllipsesOutline} />
                  <IonLabel>{group.name}</IonLabel>
                  {unreadMessageMap[group.id] > 0 && (
                    <IonBadge color="danger" slot="end">
                      {unreadMessageMap[group.id]}
                    </IonBadge>
                  )}
                </IonItem>
              </IonMenuToggle>
            );
          })}
          <IonMenuToggle autoHide={false}>
            <IonItem
              detail={false}
              button
              onClick={() => setChannelModal(true)}
              lines="none"
            >
              <IonIcon slot="start" icon={addCircleOutline} />
              <IonLabel>Add Group</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
        <IonList className="sidelist">
          <IonListHeader>Members</IonListHeader>
          {users.map((user) => {
            const group = allGroups.find(
              (g) =>
                g.customType === "1-1" &&
                g.members.find((m) => m.userId === user.userId) &&
                g.members.find((m) => m.userId === currentUser.uid) &&
                user.userId !== currentUser.uid
            );

            return (
              <IonMenuToggle key={user.userId} autoHide={false}>
                <IonItem
                  className={clsx({
                    selected:
                      channel &&
                      channel.otherUserId === user.userId &&
                      location.pathname === "/",
                  })}
                  onClick={() => handleSelectUser(user)}
                  button
                  lines="none"
                  detail={false}
                >
                  <IonIcon slot="start" icon={personOutline} />
                  <IonLabel>{user.nickname}</IonLabel>
                  {group && unreadMessageMap[group.id] > 0 && (
                    <IonBadge color="danger" slot="end">
                      {unreadMessageMap[group.id]}
                    </IonBadge>
                  )}
                </IonItem>
              </IonMenuToggle>
            );
          })}
          <IonMenuToggle autoHide={false}>
            <IonItem
              detail={false}
              button
              onClick={() => setMemberModal(true)}
              lines="none"
            >
              <IonIcon slot="start" icon={addCircleOutline} />
              <IonLabel>Add Member</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
        <IonList className="sidelist">
          <IonListHeader>Account</IonListHeader>
          <IonMenuToggle autoHide={false}>
            <IonItem
              detail={false}
              button
              onClick={() => setSettingModal(true)}
              lines="none"
            >
              <IonIcon slot="start" icon={settingsOutline} />
              <IonLabel>Settings</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonItem detail={false} button onClick={handleLogout} lines="none">
            <IonIcon slot="start" icon={logOutOutline} />
            <IonLabel>Log Out</IonLabel>
          </IonItem>
        </IonList>
        <AddMemberModal
          isOpen={memberModal}
          onClose={() => setMemberModal(false)}
        />
        <AccountSettingsModal
          isOpen={settingModal}
          onClose={() => setSettingModal(false)}
        />
        <AddChannelModal
          isOpen={channelModal}
          onClose={() => setChannelModal(false)}
        />
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
