import React from "react";
import { IonRouterOutlet } from "@ionic/react";
import { Route, Redirect } from "react-router-dom";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Home from "./pages/Home";

const AuthRoutes = () => {
  return (
    <IonRouterOutlet>
      <IonRouterOutlet id="auth">
        <Route path="/" component={Home} exact={true} />
        <Route path="/auth/login" component={Login} exact={true} />
        <Route path="/auth/signup" component={Signup} exact={true} />
        <Route component={() => <Redirect to="/" />} />
      </IonRouterOutlet>
    </IonRouterOutlet>
  );
};

export default AuthRoutes;
