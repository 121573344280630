import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

const enableMiddleware = (...middlewares) => {
  return compose(applyMiddleware(...middlewares));
};

let store;

const configureStore = initialState => {
  const sagaMiddleware = createSagaMiddleware();
  store = createStore(
    rootReducer,
    initialState,
    enableMiddleware(sagaMiddleware, logger)
  );

  let sagaManager = sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept(() => {
      const nextReducer = require("./rootReducer").default;
      store.replaceReducer(nextReducer);

      const newYieldedSagas = require("./rootSaga").default;
      sagaManager.cancel();
      sagaManager.done.then(() => {
        sagaManager = sagaMiddleware.run(newYieldedSagas);
      });
    });
  }

  return store;
};

export const getStore = () => store;
export default configureStore;
