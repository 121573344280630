export default function transformChannel(c) {
  return {
    id: c.url,
    url: c.url,
    customType: c.customType,
    name: c.name,
    coverUrl: c.coverUrl,
    members: c.members.map(m => ({
      userId: m.userId,
      nickname: m.nickname,
      profileUrl: m.profileUrl
    }))
  };
}
