import React, { useState } from "react";
import {
  IonFooter,
  IonToolbar,
  IonButtons,
  IonButton,
  IonLabel,
  IonIcon,
  IonInput,
} from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import { attachOutline, sendOutline } from "ionicons/icons";
import sendbird, { getCurrentChannel } from "../../services/sendbird";
import Upload from "../../components/form/Upload";
import ChatActions, { ChatSelectors } from "../../redux/ChatRedux";
import { AuthSelectors } from "../../redux/AuthRedux";
import transformMessage from "../../transform/message";
import { toast } from "../../toast";
import { getChannelName } from "../../utils/channel";

function MessageForm() {
  const [msg, setMsg] = useState("");
  const [sending, setSending] = useState(false);
  const channel = useSelector(ChatSelectors.selectChannel);
  const currentUser = useSelector(AuthSelectors.selectUser);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (sending) return;
    setSending(true);
    const params = new sendbird.UserMessageParams();

    params.message = msg;
    params.customType = "text"; // text type
    // params.data = DATA;
    // params.mentionType = 'users';                       // Either 'users' or 'channel'
    // params.mentionedUserIds = ['Jeff', 'Julia'];        // Or mentionedUsers = Array<User>;
    // params.metaArrayKeys = ['linkTo', 'itemType'];
    params.pushNotificationDeliveryOption = "default"; // default or suppress

    getCurrentChannel().sendUserMessage(params, function (message, error) {
      setSending(false);

      if (error) {
        toast(error.message);
        return;
      }

      setMsg("");

      dispatch(ChatActions.addMessage(transformMessage(message)));
    });
  };

  const handleUpload = async (files) => {
    const blob = files[0];
    const params = new sendbird.FileMessageParams();
    params.file = blob;
    params.fileName = `${new Date().valueOf()}.jpg`;
    params.thumbnailSizes = [{ maxWidth: 400, maxHeight: 300 }];
    params.mimeType = "image/jpeg";
    params.customType = "upload";

    getCurrentChannel().sendFileMessage(params, function (fileMessage, error) {
      if (error) {
        console.log(error);
        toast(error.message);
        return;
      }

      dispatch(ChatActions.addMessage(transformMessage(fileMessage)));
    });
  };

  const handleKeyPress = (evt) => {
    if (evt.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <IonFooter>
      <IonToolbar>
        <IonButtons slot="start">
          <Upload
            files={[]}
            onChange={handleUpload}
            placeholder={<IonIcon slot="icon-only" icon={attachOutline} />}
            disabled={sending}
          />
        </IonButtons>

        <IonLabel>
          <IonInput
            value={msg}
            placeholder={`Message ${getChannelName(channel, currentUser)}`}
            onIonChange={(e) => setMsg(e.target.value)}
            onKeyPress={handleKeyPress}
          />
        </IonLabel>

        <IonButtons slot="end">
          <IonButton disabled={sending} onClick={handleSubmit}>
            <IonIcon slot="icon-only" icon={sendOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonFooter>
  );
}

export default MessageForm;
