import { Plugins } from "@capacitor/core";

const { PushNotifications, LocalNotifications } = Plugins;

export function registerPushNotification() {
  return PushNotifications.register().catch(e => console.error(e));
}

export async function isEnabled() {
  try {
    const { value } = await LocalNotifications.areEnabled();
    return value;
  } catch (e) {
    console.error(e);
    return false;
  }
}
