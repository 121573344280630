import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Field, Form } from "formik";
import {
  IonButton,
  IonLoading,
  IonCheckbox,
  IonItem,
  IonLabel,
} from "@ionic/react";
import InputField from "../../components/InputField";
import { registerUser } from "../../services/firebase";
// import inviteService from "../../services/invite";
// import { toast } from "../../toast";
import AuthActions from "../../redux/AuthRedux";
import validationSchema from "./schema";

// @TODO move registration to backend for safety
class SignupForm extends Component {
  state = {
    terms: false,
  };

  handleSubmit = async (values, actions) => {
    const { changeAuth } = this.props;
    actions.setSubmitting(true);
    // @NOTE enable when whitelisting needed
    // try {
    //   await inviteService.checkEmail(values.email);
    // } catch (e) {
    //   toast(e.message || "Invitation not found");
    //   actions.setSubmitting(false);
    //   return;
    // }
    await registerUser(values.email, values.password, values.name, changeAuth);
    actions.setSubmitting(false);
  };

  renderForm = ({ isSubmitting }) => {
    return (
      <Form>
        <IonLoading
          message="Please wait..."
          duration={0}
          isOpen={isSubmitting}
        />
        <Field component={InputField} name="name" label="Full Name" required />
        <Field component={InputField} name="email" type="email" label="Email" />
        <Field
          component={InputField}
          name="password"
          type="password"
          label="Password"
          required
        />
        <Field
          component={InputField}
          name="passwordConfirm"
          type="password"
          label="Confirm Password"
          required
        />
        <IonItem lines="none" className="checkbox-container">
          <IonCheckbox
            slot="start"
            color="primary"
            onIonChange={() => this.setState({ terms: !this.state.terms })}
          />
          <IonLabel className="ion-text-wrap">
            <p>
              I agree to Stitch’s{" "}
              <a href="/" className="link">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="/" className="link">
                Privacy Policy
              </a>{" "}
              which includes my consent to receive marketing information from
              Stitch. I can unsubscribe from marketing communications at any
              time.
            </p>
          </IonLabel>
        </IonItem>
        <IonButton
          color="primary"
          expand="block"
          disabled={isSubmitting || !this.state.terms}
          type="submit"
        >
          Sign Up
        </IonButton>
      </Form>
    );
  };

  render() {
    return (
      <Formik
        initialValues={{
          name: "",
          email: "",
          password: "",
          passwordConfirm: "",
        }}
        onSubmit={this.handleSubmit}
        validationSchema={validationSchema}
        children={this.renderForm}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeAuth: (user) => dispatch(AuthActions.changeAuth(user)),
});

export default connect(null, mapDispatchToProps)(SignupForm);
