import React from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./PageLayout.css";

function Home({ title, children, header }) {
  return (
    <IonPage>
      {(title || header) && (
        <IonHeader>
          <IonToolbar>
            <IonTitle>{title}</IonTitle>
            {header}
          </IonToolbar>
        </IonHeader>
      )}

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {children}
      </IonContent>
    </IonPage>
  );
}

export default Home;
