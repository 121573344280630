import firebase from "./firebase";

function getCollection() {
  return firebase.firestore().collection("invites");
}

function getId(email) {
  return email.replace(/\./g, ",");
}

function inviteEmail(email) {
  const invite = firebase.functions().httpsCallable("inviteUser");

  return invite({ email });
}

function checkEmail(email) {
  return getCollection()
    .doc(getId(email))
    .get()
    .then((doc) => {
      if (doc.exists) {
        return true;
      }

      throw new Error("Invitation not found");
    });
}

export default {
  inviteEmail,
  checkEmail,
};
