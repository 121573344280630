import React from "react";
import PropTypes from "prop-types";
import { getIn } from "formik";
import { IonInput, IonItem, IonLabel, IonText } from "@ionic/react";

function InputField({ field, form, disabled, label, onChange, ...rest }) {
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const hasError = !!(touched && error);

  return (
    <IonItem className="input-container" lines="full">
      <IonLabel position="floating">{label}</IonLabel>
      <IonInput
        disabled={form.isSubmitting || disabled}
        {...rest}
        {...field}
        color="primary"
        onIonChange={field.onChange}
      />
      {hasError && (
        <IonText color="primary">
          <small>{error}</small>
        </IonText>
      )}
    </IonItem>
  );
}

InputField.propTypes = {
  label: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object,
  disabled: PropTypes.bool,
};

export default InputField;
