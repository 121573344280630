import React, { useEffect } from "react";
import { IonApp, IonLoading, isPlatform } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Plugins } from "@capacitor/core";
import { useSelector, useDispatch } from "react-redux";
import AuthActions, { AuthSelectors } from "./redux/AuthRedux";
import AppActions, { AppSelectors } from "./redux/AppRedux";
import ChatActions from "./redux/ChatRedux";
import { auth } from "./services/firebase";
import sendbird, {
  setCurrentChannel,
  getGroupChannel,
} from "./services/sendbird";
import transformChannel from "./transform/channel";
import AuthRoutes from "./AuthRoutes";
import MainRoutes from "./MainRoutes";

const { PushNotifications } = Plugins;

const App = () => {
  const appLoaded = useSelector(AppSelectors.selectLoaded);
  const authLoaded = useSelector(AuthSelectors.selectLoaded);
  const user = useSelector(AuthSelectors.selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleChangeUser = async (currentUser) => {
      dispatch(AuthActions.changeAuth(currentUser));
    };

    dispatch(AppActions.startup());
    return auth.onAuthStateChanged(handleChangeUser);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      PushNotifications.addListener("registrationError", (data) => {
        console.log("pushNotificationRegistrationError", data);
      });

      PushNotifications.addListener("registration", (data) => {
        if (isPlatform("ios")) {
          sendbird.registerAPNSPushTokenForCurrentUser(
            data.value,
            (resp, error) => {
              console.log("APN Token", resp, error);
            }
          );
        }
        console.log("pushNotificationRegistration", data);
      });

      PushNotifications.addListener("pushNotificationReceived", (data) => {
        console.log("pushNotificationReceived", data);
      });

      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        async (payload) => {
          console.log("pushNotificationActionPerformed", payload);

          const channelUrl =
            payload.notification.data.sendbird.channel.channel_url;
          const newChannel = await getGroupChannel(channelUrl);
          setCurrentChannel(newChannel);

          dispatch(ChatActions.setChannel(transformChannel(newChannel)));
        }
      );
    } catch (e) {
      console.error(e);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonApp>
      <IonLoading isOpen={!appLoaded || !authLoaded} message="Loading..." />
      {authLoaded && (
        <IonReactRouter>
          {user ? <MainRoutes /> : <AuthRoutes />}
        </IonReactRouter>
      )}
    </IonApp>
  );
};

export default App;
