import { put, select, call } from "redux-saga/effects";
import AuthActions, { AuthSelectors } from "./AuthRedux";
import ChatActions from "./ChatRedux";
import {
  connect,
  disconnect,
  listGroupChannels,
  listAllUsers,
  joinChannel,
  setCurrentChannel,
  getDefaultChannel,
} from "../services/sendbird";
import transformChannel from "../transform/channel";
import transformUser from "../transform/user";
import { registerPushNotification } from "../services/notification";

export function* changeAuth(actions) {
  const { user } = actions;
  const oldUser = yield select(AuthSelectors.selectUser);
  let sbUser;

  try {
    if (oldUser) {
      yield call(disconnect);
    }

    if (user) {
      // login
      sbUser = yield call(connect, user);
      yield call(registerPushNotification);

      const defaultChannel = yield call(getDefaultChannel, user);
      yield call(joinChannel, defaultChannel);

      const channels = yield call(listGroupChannels);
      yield put(
        ChatActions.setChannelList(channels.map((c) => transformChannel(c)))
      );

      setCurrentChannel(defaultChannel);
      yield put(ChatActions.setChannel(transformChannel(defaultChannel)));

      yield put(
        ChatActions.setUnreadCountMap(
          channels.reduce(
            (prev, c) => ({
              ...prev,
              [c.url]: c.unreadMessageCount,
            }),
            {}
          )
        )
      );

      const users = yield call(listAllUsers);
      yield put(ChatActions.setUsers(users.map((u) => transformUser(u))));
    }
  } catch (e) {
    console.error(e);
  }

  if (user) {
    yield put(
      AuthActions.setUser({
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
        profileUrl: sbUser ? sbUser.profileUrl : "",
      })
    );
  } else {
    yield put(AuthActions.setUser(null));
  }
}
