import { all, takeLatest } from "redux-saga/effects";
import { AppTypes } from "./AppRedux";
import { AuthTypes } from "./AuthRedux";
import { startup } from "./AppSaga";
import { changeAuth } from "./AuthSaga";

export default function* root() {
  yield all([takeLatest(AppTypes.STARTUP, startup)]);
  yield all([takeLatest(AuthTypes.CHANGE_AUTH, changeAuth)]);
}
