import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  IonItem,
  IonFooter,
  IonToolbar,
  IonButtons,
  IonLabel,
  IonList,
  IonButton,
  IonIcon,
  IonContent,
} from "@ionic/react";
import { checkmark } from "ionicons/icons";
import Modal from "../../components/Modal";
import { toast } from "../../toast";
import { getCurrentChannel } from "../../services/sendbird";
import { ChatSelectors } from "../../redux/ChatRedux";

function InviteModal({ isOpen, onClose }) {
  const [value, setValue] = useState([]);
  const [saving, setSaving] = useState(false);
  const currentChannel = useSelector(ChatSelectors.selectChannel);
  const members = useSelector(ChatSelectors.selectMembers);
  const users = useSelector(ChatSelectors.selectUsers);
  const isSelected = (option) => {
    return !!value.find((item) => item === option);
  };

  const handleToggle = (u) => {
    if (isSelected(u)) {
      setValue(value.filter((item) => item !== u));
    } else {
      setValue([...value, u]);
    }
  };

  const handleSave = async () => {
    setSaving(true);
    const channel = getCurrentChannel();
    channel.inviteWithUserIds(value, (resp, error) => {
      setSaving(false);

      if (error) {
        return toast(error.message);
      }

      console.log("user invited", resp);

      // @TODO handle member update on redux
      toast("Users invited");
      onClose();
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      title={`Add Member to ${currentChannel.name}`}
      onClose={onClose}
    >
      <IonContent>
        <IonList>
          {users
            .filter((u) => !members.find((m) => m.userId === u.userId))
            .map((u) => (
              <IonItem key={u.userId} onClick={() => handleToggle(u.userId)}>
                <IonLabel>{u.nickname}</IonLabel>
                {isSelected(u.userId) && (
                  <IonIcon icon={checkmark} slot="end" />
                )}
              </IonItem>
            ))}
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton
              fill="solid"
              color="primary"
              disabled={!value.length}
              loading={saving}
              onClick={handleSave}
            >
              Invite
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </Modal>
  );
}

export default InviteModal;
