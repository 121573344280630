export function getChannelName(channel, currentUser) {
  if (!channel) return "";

  if (channel.customType === "group") return channel.name;
  if (channel.customType === "1-1") {
    const users = channel.members.filter((m) => m.userId !== currentUser.uid);
    if (users.length) {
      return users[0].nickname;
    }

    return currentUser.displayName;
  }

  return "";
}
