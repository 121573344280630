import React, { useEffect, useState } from "react";
import { IonList, IonLoading } from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import { v4 } from "uuid";
import sendbird, { getCurrentChannel } from "../../services/sendbird";
import ChatActions, { ChatSelectors } from "../../redux/ChatRedux";
import Message from "./Message";
import transformMessage from "../../transform/message";
import { toast } from "../../toast";

function MessageList() {
  const channel = useSelector(ChatSelectors.selectChannel);
  const messages = useSelector(ChatSelectors.selectMessages);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (!channel) return;

    const loadMessages = () => {
      const prevMessageListQuery = getCurrentChannel().createPreviousMessageListQuery();
      prevMessageListQuery.limit = 100;
      prevMessageListQuery.reverse = false;

      setLoading(true);

      prevMessageListQuery.load(function (messages, error) {
        setLoading(false);

        if (error) {
          toast(error.message);
          return;
        }

        dispatch(
          ChatActions.setMessages(messages.map((m) => transformMessage(m)))
        );
      });
    };
    loadMessages();

    const ChannelHandler = new sendbird.ChannelHandler();
    const uuid = v4();

    ChannelHandler.onMessageReceived = (msgChannel, message) => {
      if (msgChannel.url === channel.id) {
        dispatch(ChatActions.addMessage(transformMessage(message)));
        msgChannel.markAsRead();
      }
    };
    sendbird.addChannelHandler(uuid, ChannelHandler);

    return () => {
      sendbird.removeChannelHandler(uuid);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return (
    <IonList>
      {loading ? (
        <IonLoading isOpen />
      ) : (
        messages.map((msg) => <Message key={msg.messageId} data={msg} />)
      )}
    </IonList>
  );
}

export default MessageList;
