import { combineReducers } from "redux";
import { reducer as app } from "./AppRedux";
import { reducer as auth } from "./AuthRedux";
import { reducer as chat } from "./ChatRedux";

const reducers = combineReducers({
  app,
  auth,
  chat
});

export default reducers;
