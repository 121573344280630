import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import { toast } from "../toast";

const firebaseConfig = {
  apiKey: "AIzaSyBxMe0BSzGj7YFTFZeRJ15yWRKyxT8cUew",
  authDomain: "stitch-78c2b.firebaseapp.com",
  databaseURL: "https://stitch-78c2b.firebaseio.com",
  projectId: "stitch-78c2b",
  storageBucket: "stitch-78c2b.appspot.com",
  messagingSenderId: "40906280661",
  appId: "1:40906280661:web:02a73af228d027c5209c5c",
  measurementId: "G-KN2W69T417",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const functions = firebase.functions();

export async function loginUser(email, password) {
  try {
    await firebase.auth().signInWithEmailAndPassword(email, password);
    toast("You have logged in successfully!");
    return true;
  } catch (error) {
    toast(error.message, 4000);
    return false;
  }
}

export async function registerUser(email, password, name, callback) {
  try {
    const res = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    await res.user.updateProfile({
      displayName: name,
    });
    if (callback) {
      await callback(res.user);
    }

    toast("You have signed up successfully!");
    return true;
  } catch (error) {
    toast(error.message, 4000);
    return false;
  }
}

export default firebase;
